export const searchFilterTypes = [
  'selected_filters',
  'categories',
  'now_available',
  'location',
];

export const replacePlaceholders = (template, values) => {
  return template.replace(/\[([^\]]+)\]/g, (_, key) => values[key]);
};
