import { useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import usePrevious from 'hooks/use-previous-state';
import { useTranslations } from 'next-intl';
import useMount from 'hooks/use-mount';

import { SimpleDialog } from 'Components/Shared/UI';
import {
  Login,
  Register,
  SendMessage,
  GoToProfile,
  ConfirmEmail,
  ForgotPassword,
} from 'Components/Dumb/DialogComponents';

import getProfileWithoutRequiredField from 'utils/getProfileWithoutRequiredField';

import { authSelector } from 'store/reselect';
import { setLoginByDialog } from 'store/actions';

const LoginByDialog = ({ profileId, adId }) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const authState = useSelector(authSelector());
  const [message, setMessage] = useState('');
  const [loadingIcon, setLoadingIcon] = useState(false);
  const { loginByPopUp } = authState;
  const previousContent = usePrevious(loginByPopUp, true);

  const [disableOkBtn, setDisableOkBtn] = useState(false);
  const [currentComponent, setCurrentComponent] = useState({});

  const loginRef = useRef();
  const registerRef = useRef();
  const forgotPassRef = useRef();
  const sendMessageRef = useRef();
  const confirmEmailRef = useRef();

  const modalOk = async () => {
    try {
      if (disableOkBtn) return;
      setLoadingIcon(true);
      await currentComponent?.call?.({
        from_profile_id: profileId,
        from_type: authState.user.role,
        ad_id: adId,
        message_body: message,
        account_id: authState.user.id,
      });
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setLoginByDialog(null));
      setLoadingIcon(false);
    }
  };

  const submitText = useCallback(
    txt => (loadingIcon ? <span className="loading-icon"></span> : txt),
    [loadingIcon],
  );

  const assignCallMethod = (ref, methodName) => {
    setCurrentComponent({ call: ref.current?.[methodName] });
  };

  const profileWithoutRequiredField = getProfileWithoutRequiredField(
    authState.accountProfiles,
  );

  useMount(() => () => {
    dispatch(setLoginByDialog(null));
  });

  const ModalComponents = {
    'send-message': {
      title: t('create_profile_popup_title'),
      Component: (
        <SendMessage
          message={message}
          setMessage={setMessage}
          ref={sendMessageRef}
          emitToActive={v => setDisableOkBtn(!v)}
          triggerModalOk={modalOk}
          mounted={() => {
            setDisableOkBtn(true);
            assignCallMethod(sendMessageRef, 'sendMessage');
          }}
        />
      ),
      actions: {
        cancel: t('header_cancel_button'),
        ok: submitText(t('reset_password_button_text')),
      },
    },
    'edit-profile': {
      title: t('create_profile_popup_title'),
      Component: (
        <GoToProfile
          href={`/my-sexjobs/profile/edit/${profileWithoutRequiredField?._id}`}
        />
      ),
      actions: {
        cancel: t('header_cancel_button'),
      },
    },
    'create-profile': {
      title: 'Send a message',
      Component: <GoToProfile href="/my-sexjobs/profile/create" />,
      actions: {
        cancel: t('header_cancel_button'),
      },
    },
    login: {
      title: t('login_title'),
      Component: (
        <Login
          ref={loginRef}
          emitToActive={v => setDisableOkBtn(!v)}
          triggerModalOk={modalOk}
          mounted={() => {
            setDisableOkBtn(true);
            assignCallMethod(loginRef, 'onLogin');
          }}
        />
      ),
      actions: {
        cancel: t('header_cancel_button'),
        ok: submitText(t('login_title')),
      },
    },
    register: {
      title: t('register_title'),
      Component: (
        <Register
          ref={registerRef}
          emitToActive={v => setDisableOkBtn(!v)}
          triggerModalOk={modalOk}
          mounted={() => {
            setDisableOkBtn(true);
            assignCallMethod(registerRef, 'onRegister');
          }}
        />
      ),
      actions: {
        cancel: t('header_cancel_button'),
        ok: submitText(t('create_button_text')),
      },
    },
    'forgot-password': {
      title: t('login_forgot_password_text'),
      Component: (
        <ForgotPassword
          ref={forgotPassRef}
          emitToActive={v => setDisableOkBtn(!v)}
          triggerModalOk={modalOk}
          mounted={() => {
            setDisableOkBtn(true);
            assignCallMethod(forgotPassRef, 'onSend');
          }}
        />
      ),
      actions: {
        cancel: t('header_cancel_button'),
        ok: submitText(t('confirm_button_text')),
      },
    },
    'confirm-email': {
      title: 'Confirm Email',
      Component: (
        <ConfirmEmail
          ref={confirmEmailRef}
          mounted={() => {
            setDisableOkBtn(false);
            assignCallMethod(confirmEmailRef, null);
          }}
        />
      ),
      actions: {
        ok: 'Done',
      },
    },
  };

  return (
    <SimpleDialog
      width={360}
      open={!!loginByPopUp}
      disableOkBtn={disableOkBtn}
      onOk={modalOk}
      onClose={() => dispatch(setLoginByDialog(null))}
      actions={ModalComponents[loginByPopUp || previousContent]?.actions}
      title={ModalComponents[loginByPopUp || previousContent]?.title}
    >
      {ModalComponents[loginByPopUp || previousContent]?.Component}
    </SimpleDialog>
  );
};

export default LoginByDialog;
