import { memo } from 'react';
import PropTypes from 'prop-types';
import { FaCoins } from 'react-icons/fa';
import { BiUser } from 'react-icons/bi';
import { AiOutlineClockCircle, AiOutlineTag } from 'react-icons/ai';
import { useTranslations } from 'next-intl';

import { convertCapitalize } from 'utils/handlers';

const VacancyDetails = ({ workingHours, employmentType, salary, workType }) => {
  const t = useTranslations();

  const employmentTypeTitle = `${t('vacancy_details_employment_type_title')}: ${
    employmentType ? t('employment_type_commission') : '-'
  }`;

  return (
    <div className="content-with-shadow">
      <div>
        <AiOutlineTag
          size={25}
          style={{ color: 'rgb(255, 255, 255)', margin: '10 10' }}
        />
        <span style={{ color: 'rgb(255, 255, 255)' }}>{`${t(
          'vacancy_details_work_type_title',
        )}: ${workType ? t(workType) : '-'}`}</span>
      </div>
      <div>
        <AiOutlineClockCircle
          size={25}
          style={{ color: 'rgb(255, 255, 255)', margin: '10 10' }}
        />
        <span style={{ color: 'rgb(255, 255, 255)' }}>{`${t(
          'vacancy_details_working_hours_title',
        )}: `}</span>
        <span style={{ color: 'rgb(255, 255, 255)' }}>
          {workingHours.map(
            (item, index) =>
              `${index === 0 ? '' : ','} ${t(`working_hours_${item}`)}`,
          )}
        </span>
      </div>
      {salary.min && salary.max && salary.duration && (
        <div>
          <FaCoins
            size={25}
            style={{ color: 'rgb(255, 255, 255)', margin: '10 10' }}
          />
          <span style={{ color: 'rgb(255, 255, 255)' }}>{`${t(
            'vacancy_details_earnings_title',
          )} min. €${salary.min}/${t(salary.duration)} | max. €${
            salary.max
          }/${t(salary.duration)}`}</span>
        </div>
      )}
      <div>
        <BiUser
          size={25}
          style={{ color: 'rgb(255, 255, 255)', margin: '10 10' }}
        />
        <span style={{ color: 'rgb(255, 255, 255)' }}>
          {employmentTypeTitle}
        </span>
      </div>
    </div>
  );
};
VacancyDetails.propTypes = {
  data: PropTypes.object.isRequired,
};
export default memo(VacancyDetails);
