export const updateFiltersState = ({
  key,
  state,
  value,
  label,
  section,
  groupName,
}) => {
  if (Array.isArray(state[key])) {
    return {
      ...state,
      [key]: state[key].map(item =>
        item.label === label ? { ...item, value: value } : item,
      ),
    };
  }

  const isSelectedTabs = groupName === 'tabs';
  const isSelectedSection = groupName === 'section';

  if (isSelectedTabs) {
    return {
      ...state,
      [key]: {
        ...state[key],
        [groupName]: state[key][groupName].map(item => ({
          ...item,
          value: item.label === label,
        })),
      },
    };
  } else if (isSelectedSection) {
    return {
      ...state,
      [key]: {
        ...state[key],
        [groupName]: {
          ...state[key][groupName],
          [section]: state[key][groupName][section].map(item =>
            item.label === label ? { ...item, value: value } : item,
          ),
        },
      },
    };
  } else {
    const selectedTab = state[key].tabs?.find(({ value }) => value);

    return {
      ...state,
      [key]: {
        ...state[key],
        [groupName]: state[key][groupName].map(item =>
          item.label === label && selectedTab.tab === item.tab
            ? { ...item, value: value }
            : item,
        ),
      },
    };
  }
};

export const getFilteredAppearance = ({
  title,
  filters,
  isSelectedMale,
  isSelectedFemale,
}) => {
  if ((isSelectedFemale || isSelectedMale) && title === 'appearance') {
    return {
      section: Object.entries(filters.section).reduce((acc, [key, value]) => {
        if (
          (key === 'cup_size' && isSelectedMale) ||
          (key === 'penis_length' && isSelectedFemale)
        ) {
          return acc;
        }
        acc[key] = value;
        return acc;
      }, {}),
    };
  }

  return filters;
};

export const getSelectedGenders = filters => {
  const genders = filters?.gender?.reduce((acc, el) => {
    acc[el.label] = el;

    return acc;
  }, {});

  const male = genders?.male;
  const female = genders?.female;

  const isSelectedMale = !!male?.value && !female?.value;
  const isSelectedFemale = !male?.value && !!female?.value;

  return { isSelectedFemale, isSelectedMale };
};

export const getFiltersWithTrueValue = filters => {
  const result = {};

  for (const category in filters) {
    if (Array.isArray(filters[category])) {
      if (filters[category].some(option => option.value)) {
        result[category] = filters[category].filter(({ value }) => value);
      }
    } else {
      const { tabs, options, section } = filters[category];
      const hasGroup = tabs?.some(option => option.value);
      const hasOptions = options?.some(option => option.value);
      const hasSection = Object.values(section || {})
        .flat()
        ?.some(({ value }) => value);

      if (hasOptions || hasGroup) {
        result[category] = {
          ...(hasGroup && {
            tabs: filters[category].tabs.filter(({ value }) => value),
          }),
          ...(hasOptions && {
            options: filters[category].options.filter(({ value }) => value),
          }),
        };
      } else if (hasSection) {
        result[category] = { section: {} };
        const { isSelectedFemale, isSelectedMale } =
          getSelectedGenders(filters);
        Object.entries(section || {}).forEach(([key, data]) => {
          if (data.some(option => option.value)) {
            if (
              (isSelectedFemale && key === 'penis_length') ||
              (isSelectedMale && key === 'cup_size')
            ) {
              return;
            }
            result[category].section[key] = data.filter(({ value }) => value);
          }
        });
      }
    }
  }
  return result;
};

const getSelectedFilterName = (groupName, group) => ({
  count: group.length,
  label: group.length === 1 ? group[0].label : groupName,
  value: group.length === 1 ? group[0].label : `filters_${groupName}_title`,
});

export const getSelectedFilters = (selectedFilters = {}) => {
  return Object.entries(selectedFilters).reduce((acc, [groupName, group]) => {
    const isArrayGroup = Array.isArray(group);

    if (isArrayGroup) {
      acc.push(getSelectedFilterName(groupName, group));
    } else {
      const { section, tabs, options } = group;

      if (tabs) {
        acc.push(getSelectedFilterName(groupName, tabs));
      }

      if (options) {
        acc.push(
          getSelectedFilterName(
            groupName,
            options.filter(({ tab }) => tab === tabs[0]?.tab),
          ),
        );
      }

      if (section) {
        const { isSelectedFemale, isSelectedMale } =
          getSelectedGenders(selectedFilters);
        Object.entries(section).forEach(([key, value]) => {
          if (
            (isSelectedFemale && key === 'penis_length') ||
            (isSelectedMale && key === 'cup_size')
          ) {
            return;
          }
          acc.push(getSelectedFilterName(key, value));
        });
      }
    }

    return acc;
  }, []);
};

export function sortFilterByLabel(array, t) {
  return array.sort((a, b) => {
    const labelA = t(a.label.toLowerCase());
    const labelB = t(b.label.toLowerCase());

    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });
}

export const sortableFilters = ['sex_options'];
