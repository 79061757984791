// eslint-disable-next-line import/prefer-default-export
export const generateBreadcrumbsPath = (
  slug = [],
  selectedCategory,
  selectedSubCategory,
) => {
  const breadcrumbPaths = slug.reduce((acc, item) => {
    if (selectedCategory?.value_nl === item) {
      acc[selectedCategory.title] = item;
      return acc;
    }

    if (selectedSubCategory?.value_nl === item) {
      acc[selectedSubCategory.title] = item;
      return acc;
    }

    acc[item] = item;

    return acc;
  }, {});

  return breadcrumbPaths;
};
