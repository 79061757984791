import React, { useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import jwt_decode from 'jwt-decode';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslations } from 'next-intl';
import useMount from 'hooks/use-mount';

import { SimpleInput } from 'Components/Shared/UI';

import { saveToStorage } from 'utils/persist';
import ValidatorsState from 'utils/validators';
import { Cookie, ifFormIsValid } from 'utils/handlers';

import { validators } from 'store/reselect';
import { setLoginByDialog, setUserData } from 'store/actions';

import API from 'services/api';

import useStyles from './styles';

const Login = React.forwardRef(
  ({ mounted, triggerModalOk, emitToActive }, ref) => {
    const t = useTranslations();
    const styles = useStyles();
    const dispatch = useDispatch();
    const validatorsPattern = useSelector(validators());
    const validatorsExec = ValidatorsState({
      emailPattern: validatorsPattern.emailPattern,
    });
    const { register, errors, reset, formState, getValues, setValue, watch } =
      useForm({
        mode: 'onKeypress',
      });

    useImperativeHandle(ref, () => ({
      onLogin() {
        return new Promise(async (resolve, reject) => {
          if (document.activeElement) {
            document.activeElement.blur();
          }
          try {
            const { password, email } = getValues();
            const { data } = await API.getAuthTokens({
              password,
              email,
              cookie_hash: Cookie.getCookieByKey('ch'),
              user_agent: navigator.userAgent,
            });

            if (data) {
              const userTokenData = jwt_decode(data.access_token);
              Cookie.setToken(data.access_token);
              saveToStorage('refreshToken', data.refresh_token);
              Cookie.setCookieByKey('lang', userTokenData.language);
              const userData = await API.getAccount({ id: userTokenData.id });
              dispatch(setUserData({ ...userTokenData, ...userData.data }));
            }
            resolve();
          } catch (err) {
            reset();
            reject(err);
          }
        });
      },
    }));

    const goTo = comp => {
      dispatch(setLoginByDialog(comp));
    };

    const triggerOk = evt => {
      if (evt.key === 'Enter') {
        evt.preventDefault();
        triggerModalOk();
      }
    };

    useEffect(() => {
      emitToActive(ifFormIsValid(['email', 'password'], getValues(), errors));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState]);

    useMount(() => {
      mounted();
    });

    return (
      <div className={styles['login-form--block']}>
        <p>
          {t('login_popup_info_text_1')}
          <span style={{ cursor: 'pointer' }} onClick={() => goTo('register')}>
            {t('login_popup_info_text_2')}
          </span>
        </p>
        <form onKeyDown={triggerOk}>
          <SimpleInput
            required
            fullWidth
            type="email"
            name="email"
            margin={[0, 0, 16, 0]}
            value={watch('email')}
            errors={errors.email && errors.email.message}
            customStylesInput={{ border: '1px solid #D8D8D8' }}
            registerRef={register(validatorsExec.EMAIL_VALIDATOR)}
            label={{ title: t('change_email_input_1'), color: '#000' }}
            onChange={e => setValue('email', e.target.value)}
          />
          <SimpleInput
            fullWidth
            passwordEye
            name="password"
            type="password"
            margin={[0, 0, 16, 0]}
            value={watch('password')}
            customStylesInput={{ border: '1px solid #D8D8D8' }}
            label={{ title: t('change_email_input_2'), color: '#000' }}
            registerRef={register(validatorsExec.PASSWORD_VALIDATOR)}
            onChange={e => setValue('password', e.target.value)}
            errors={
              (errors.password && errors.password.message) ||
              (errors.password &&
                errors.password.type === 'containsSymbol' &&
                'Use at least one symbol') ||
              (errors.password &&
                errors.password.type === 'containsDigit' &&
                'Use 1 ore more numbers') ||
              (errors.password &&
                errors.password.type === 'containsUppercaseLowercase' &&
                t('upper_lower_text'))
            }
          />
          <span
            className="forgot-your-pass"
            onClick={() => goTo('forgot-password')}
          >
            {t('login_forgot_password_text')}
          </span>
        </form>
      </div>
    );
  },
);
Login.defaultProps = {
  triggerModalOk: () => void 0,
};
Login.propTypes = {
  mounted: PropTypes.func.isRequired,
  emitToActive: PropTypes.func.isRequired,
  triggerModalOk: PropTypes.func,
};
export default React.memo(Login);
