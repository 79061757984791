import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';
import { MediaQuery, Shadow } from 'static/styles/jss/abstracts/mixins';

export default makeStyles({
  'page-404': {
    margin: '60px 0',
    padding: '30px',
    backgroundColor: variables.$baseColorLight_30,
    ...Shadow('0 3px 6px 0 rgba(0,0,0,0.15)'),
    display: 'flex',
    ...MediaQuery.down({
      flexDirection: 'column',
    }).xs,
    '& h1': {
      fontSize: '60px',
      color: '#fff',
      fontWeight: 'bold',
    },
  },
  'page-404__description': {
    ...MediaQuery.up({
      paddingLeft: '30px',
    }).xs,
    '& h3': {
      color: '#FFFFFF',
      fontSize: '18px',
      fontWeight: 'bold',
      lineHeight: '21px',
      marginBottom: '10px',
    },
    '& p': {
      color: variables.$greyTxtColor,
      fontSize: '15px',
      lineHeight: '17px',
      margin: '0',
      '& a': {
        color: '#E7E00E',
      },
    },
  },
});
