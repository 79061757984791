import { useEffect, useState } from 'react';
import { Container, Col } from 'reactstrap';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import useStyles from 'static/styles/jss/base/globalPages';
import { useTranslations } from 'next-intl';
import { FaPhoneAlt, FaCoins } from 'react-icons/fa';
import useMemoSelector from 'hooks/useMemoSelector';

import GallerySlider from 'Components/Plugins/GallerySlider';
import Canonical from 'Components/Canonical';
import { SimpleInput, Button, SimpleDialog } from 'Components/Shared/UI';
import Breadcrumbs from 'Components/Dumb/Breadcrumbs';
import Advertisers from 'Components/Ads/View/Advertisers';
import AdvertisementInfo from 'Components/Ads/View/AdvertisementInfo';
import Description from 'Components/Ads/View/Description';
import Possibilities from 'Components/Ads/View/Possibilities';
import Page404 from 'Components/ErrorPages/404';
import Availability from 'Components/Ads/View/Availability';
import EventDetails from 'Components/Ads/View/EventDetails';
import VacancyDetails from 'Components/Ads/View/VacancyDetails';
import Prices from 'Components/Ads/View/Prices';
import SendMessageByDialog from 'Components/Account/SendMessageByDialog';

import { MEDIA, COUNTRY_PHONES } from 'utils/constants';
import { Cookie } from 'utils/handlers';
import truncateString from 'utils/truncateString';

import {
  getAds,
  baseSelector,
  authSelector,
  adCategories,
} from 'store/reselect';

import API from 'services/api';

const defaultCode = COUNTRY_PHONES.find(e => e.key === 'nl');

const defaultImageSrc = '/images/icons/upload.png';

const generateImgPath = (photos, thumb) => {
  if (photos?.length === 1) {
    const [{ path, ext, v }] = photos;
    const cuttedPath = path.substr(0, path.indexOf('.'));
    return [`${MEDIA.storagePath}/${cuttedPath}_${thumb}.${ext}?v=${v}`];
  } else {
    const imgPaths = [];
    photos
      ?.filter(el => el)
      .forEach(({ path = '', ext = '', v }) => {
        const cuttedPath = path.substr(0, path.indexOf('.'));
        const newGeneratedPath = `${MEDIA.storagePath}/${cuttedPath}_${
          thumb ?? 'base_thumb'
        }.${ext}?v=${v}`;
        imgPaths.push(newGeneratedPath);
      });
    return imgPaths;
  }
};

const ViewOfAd = ({
  data,
  isAdValid,
  imgPaths,
  profileData,
  adPromotions,
  adStatistics,
  phoneNumberConfig,
}) => {
  const styles = useStyles();
  const router = useRouter();
  const t = useTranslations();
  const [credits, setCredits] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const baseState = useSelector(baseSelector());
  const { accountProfiles, user } = useSelector(authSelector());

  const { deviceType } = baseState.deviceParams;

  const {
    type,
    city,
    title,
    prices,
    labels,
    country,
    location,
    services,
    start_date,
    description,
    category_id,
    working_hours,
    phone_numbers,
    vacancy_salary,
    employment_type,
    phone_instruction,
    vacancy_working_hours,
    _meta: { date_modified: lastModified } = {},
    _id: id,
    _changes: {
      fields: {
        description: notApprovedDescription,
        title: notApprovedTitle,
      } = {},
    } = {},
  } = data;

  const altDescription = notApprovedDescription?.[0].value.value;
  const altTitle = notApprovedTitle?.[0].value.value;

  const isEvent = type === 'event';
  const isVacancy = type === 'vacancy';

  const { id: userId, categoryList } = useMemoSelector(store => ({
    ads: getAds(store),
    id: store.auth.user.id,
    role: store.auth.user.role,
    categoryList: adCategories(store),
  }));

  const { title: currentCategoryName, value_nl } =
    categoryList.find(({ id }) => Number(id) === category_id) || {};

  const referenceId = router.asPath.slice(router.asPath.lastIndexOf('_') + 1);

  const hasLabel = labels?.length > 0;

  const singleLabel = hasLabel
    ? {
        name: labels?.[0].name,
        slug: labels?.[0].slug,
      }
    : null;

  useEffect(() => {
    if (id) {
      API.sendAdVisitor({
        uid: Cookie.getCookieByKey('ch'),
        ad_id: id,
      });
    }
  }, [id]);

  useEffect(() => {
    if (!credits && user.id) {
      API.getCreditsCount(userId).then(({ data: { credits } }) =>
        setCredits(credits),
      );
    }
  }, [userId, credits, user]);

  const handleClick = hasCredit => {
    if (!hasCredit) {
      router.push({
        pathname: '/my-sexjobs/credits',
      });
    } else {
      API.payWithCredits({
        reference_ids: [Number(referenceId)],
        account_id: user.id,
      }).then(({ data: { result } }) => result && setSuccess(true));
    }
  };

  const imgLinks = generateImgPath(imgPaths, 'orig_thumb');

  const eventType = t(labels?.[0]?.name || '');

  const isMobile = deviceType === 'mobile';

  return isAdValid ? (
    <>
      <Container>
        <Canonical />
        <NextSeo title={title} description={truncateString(description)} />

        <Col xs={12}>
          <Breadcrumbs
            roadMap={{
              defaultPath: 'home',
              morePaths: {
                ...(currentCategoryName && {
                  [currentCategoryName]: `/${value_nl}`,
                }),
                ...(hasLabel && {
                  [singleLabel.name]: `/${singleLabel.slug}`,
                }),
                ...(location?.settlement && {
                  [location.settlement]: `#`,
                }),
              },
            }}
          />
        </Col>

        {isMobile ? (
          <Col xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
            <h1 className="title-content" style={{ marginBottom: '10px' }}>
              {title ?? altTitle}
            </h1>
            {adPromotions?.tagline && (
              <span
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  width: 'max-content',
                  margin: '0px 10px 15px 0px',
                }}
                className={`sticker__tile sticker__tile--${
                  adPromotions?.gold ? 'gold' : 'silver'
                }`}
              >
                {adPromotions?.tagline}
              </span>
            )}
          </Col>
        ) : (
          <Col xs={12} style={{ display: 'flex' }}>
            {adPromotions?.tagline && (
              <span
                style={{
                  marginRight: 10,
                  marginTop: '2px',
                  fontSize: '14px',
                  fontWeight: 'bold',
                }}
                className={`sticker__tile sticker__tile--${
                  adPromotions?.gold ? 'gold' : 'silver'
                }`}
              >
                {adPromotions?.tagline}
              </span>
            )}
            <h1 className="title-content" style={{ marginBottom: '10px' }}>
              {title ?? altTitle}
            </h1>
          </Col>
        )}
        <div className={clsx(styles['grid-page-ad-view'], 'grid-page-ad-view')}>
          <div className="mb-20" style={{ margin: '0 8px' }}>
            <GallerySlider
              images={imgLinks.length ? imgLinks : [defaultImageSrc]}
            />
            {isMobile && (
              <div className="mt-20">
                <Advertisers
                  adId={id}
                  phoneNumbers={phone_numbers}
                  country={country}
                  city={location?.settlement || city}
                  adPromotions={adPromotions}
                  profileData={profileData}
                  bodyPaddingBottom={isMobile}
                  additionalInformation={{ location, country }}
                  createdDate={profileData[0]?._meta.date_created}
                  disableMessaging={!phone_instruction?.includes('messenger')}
                />
              </div>
            )}
            <div className="mt-20">
              {isEvent && (
                <EventDetails
                  event_type={eventType}
                  start_date={start_date}
                  location={location}
                />
              )}
              {isVacancy && (
                <VacancyDetails
                  salary={vacancy_salary}
                  workType={labels[0]?.name}
                  employmentType={employment_type}
                  workingHours={vacancy_working_hours}
                />
              )}
              <div className="mb-3">
                <Description
                  description={description ?? altDescription}
                  title={t('create_ad_step_three_title_2')}
                />
              </div>
              {!isEvent && !isVacancy && !!services?.length && (
                <Possibilities data={services} />
              )}
              {!isVacancy && !!prices?.length && (
                <Prices data={prices} isEvent={isEvent} />
              )}
              {!isEvent && !isVacancy && !!working_hours?.length && (
                <div className="mb-3">
                  <Availability data={working_hours} />
                </div>
              )}
              {isMobile && (
                <>
                  <AdvertisementInfo
                    data={data}
                    labels={labels}
                    isEvent={isEvent}
                    isVacancy={isVacancy}
                    adStatistics={adStatistics}
                    referenceId={referenceId}
                    lastModified={lastModified}
                    currentCategoryName={currentCategoryName}
                    createdDate={profileData[0]?._meta.date_created}
                  />
                  <Button
                    fullWidth
                    margin={[0, 0, 20, 0]}
                    text={t('ads_detailed_top_button_text')}
                    typeButton="alt"
                    icon={{ direction: 'left', className: 'icon-Heart' }}
                    customStyles={{
                      boxShadow: '0px 0px 0px 1px #c3cbd5 inset',
                    }}
                    onClick={() => setIsOpen(true)}
                  />
                  <Button
                    fullWidth
                    margin={[0, 0, 20, 0]}
                    text={t('ads_detailed_back_button_text')}
                    typeButton="alt"
                    onClick={() => router.back()}
                    icon={{ direction: 'left', className: 'icon-Back' }}
                    customStyles={{
                      boxShadow: '0px 0px 0px 0px #c3cbd5 inset',
                      backgroundColor: '#3378d1',
                      color: 'white',
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <div className="grid-area-advertisers">
            {deviceType !== 'mobile' ? (
              <>
                <Advertisers
                  adId={id}
                  phoneNumbers={phone_numbers}
                  country={country}
                  city={location?.settlement || city}
                  adPromotions={adPromotions}
                  profileData={profileData}
                  bodyPaddingBottom={isMobile}
                  additionalInformation={{ location, country }}
                  disableMessaging={!phone_instruction?.includes('messenger')}
                />
                <AdvertisementInfo
                  data={data}
                  labels={labels}
                  isEvent={isEvent}
                  isVacancy={isVacancy}
                  adStatistics={adStatistics}
                  referenceId={referenceId}
                  lastModified={lastModified}
                  createdDate={profileData[0]?._meta.date_created}
                  currentCategoryName={currentCategoryName}
                />
                <Button
                  fullWidth
                  text={t('ads_detailed_top_button_text')}
                  typeButton="alt"
                  icon={{ direction: 'left', className: 'icon-Heart' }}
                  customStyles={{
                    boxShadow: '0px 0px 0px 1px #c3cbd5 inset',
                  }}
                  onClick={() => setIsOpen(true)}
                />
                <Button
                  fullWidth
                  margin={[0, 0, 20, 0]}
                  text={t('ads_detailed_back_button_text')}
                  typeButton="alt"
                  onClick={() => router.back()}
                  icon={{ direction: 'left', className: 'icon-Back' }}
                  customStyles={{
                    boxShadow: '0px 0px 0px 0px #c3cbd5 inset',
                    backgroundColor: '#3378d1',
                    color: 'white',
                    marginTop: '10px',
                  }}
                />
              </>
            ) : (
              ''
            )}
            {isOpen && (
              <SimpleDialog
                open={isOpen}
                title={t('ad_bump_title')}
                onOk={() => {
                  setSuccess(false);
                  setIsOpen(false);
                }}
                actions={{
                  ok: !success ? t('header_cancel_button') : 'Ok',
                }}
              >
                {!success ? (
                  <div className={styles['confirm-cancel-dialog_ad']}>
                    {user.id && (
                      <>
                        <FaCoins
                          size={20}
                          style={{ marginRight: '5px', marginBottom: '5px' }}
                        />
                        <b>
                          {credits
                            ? t('ad_bump_credits_title')
                            : t('ad_bump_buy_credits_title')}
                        </b>
                        <p>
                          {credits
                            ? t('ad_bump_credits_text')
                            : t('ad_bump_buy_credits_text')}
                        </p>
                        <Button
                          onClick={() => handleClick(!!credits)}
                          text={
                            credits
                              ? t('ad_bump_credits_button_text')
                              : t('ad_bump_buy_credits_button_text')
                          }
                          backgroundColor="#6dba02"
                          typeButton={credits ? 'cta' : 'primary'}
                          fullWidth
                        />
                        <div
                          style={{
                            width: '100%',
                            height: '1px',
                            border: '1px solid',
                            marginTop: '25px',
                          }}
                        ></div>
                      </>
                    )}
                    <div style={{ marginTop: '15px' }}>
                      <div style={{ marginBottom: '15px' }}>
                        <FaPhoneAlt
                          size={20}
                          style={{
                            marginRight: '5px',
                            marginBottom: '5px',
                          }}
                        />
                        <b>{t('ad_bump_call_title')}</b>
                      </div>
                      <div
                        style={{
                          border: '1px solid #cec3c3',
                          padding: '10px',
                          marginBottom: '15px',
                        }}
                      >
                        <img
                          src={defaultCode.flagSrc}
                          alt="flag"
                          style={{
                            width: '30px',
                            height: '20px',
                            marginRight: '10px',
                          }}
                        />
                        <b
                          style={{
                            color: '#36454F',
                            marginRight: '5px',
                          }}
                        >
                          {phoneNumberConfig}
                        </b>
                        <i style={{ fontSize: '10px' }}>
                          {t('bump_price_info')}
                        </i>
                      </div>
                    </div>
                    <p>{t('ad_bump_call_text')}</p>
                    <SimpleInput
                      disabled
                      value={referenceId}
                      fullWidth
                      customStylesInput={{
                        background: '#f9c79f',
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fontSize: '17px',
                        color: '#36454F',
                      }}
                    />
                  </div>
                ) : (
                  <h5>{t('random_ad_bump_success_text')}</h5>
                )}
              </SimpleDialog>
            )}
          </div>
        </div>
      </Container>
      <SendMessageByDialog adId={id} profileId={accountProfiles[0]?._id} />
    </>
  ) : (
    <Page404 />
  );
};

export default ViewOfAd;
