const mergeByLabel = data => el =>
  data.find(({ label, tab }) =>
    tab ? el.tab === tab && label === el.label : label === el.label,
  ) || el;

function mergeRecursive(obj1, obj2) {
  const merged = { ...obj1 };

  for (const key in obj2) {
    if (obj2[key]) {
      if (typeof obj2[key] === 'object' && !Array.isArray(obj2[key])) {
        if (
          obj1[key] &&
          typeof obj1[key] === 'object' &&
          !Array.isArray(obj1[key])
        ) {
          merged[key] = mergeRecursive(obj1[key], obj2[key], 'label');
        } else {
          merged[key] = { ...obj2[key] };
        }
      } else {
        merged[key] = merged[key].map(mergeByLabel(obj2[key]));
      }
    }
  }

  return merged;
}

export default mergeRecursive;
