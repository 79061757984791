import { FaArrowLeft } from 'react-icons/fa';
import { useTranslations } from 'next-intl';

import useStyles from './styles';

const Header = ({ handleCloseFilters, handleResetFilters }) => {
  const t = useTranslations();

  const { header } = useStyles();

  return (
    <div className={header}>
      <FaArrowLeft onClick={handleCloseFilters} size={16} className="arrow" />
      <div className="title">Filter</div>
      <div className="clear-all" onClick={handleResetFilters}>
        {t('clear_all')}
      </div>
    </div>
  );
};

export default Header;
