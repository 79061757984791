import { useEffect, useState, useCallback } from 'react';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';
import { useDispatch } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import useMemoSelector from 'hooks/useMemoSelector';
import useMount from 'hooks/use-mount';

import Canonical from 'Components/Canonical';
import MySJSimpleTitleBar from 'Components/Dumb/MySJSimpleTitleBar';
import { LabelPicker } from 'Components/Plugins';
import Pagination from 'Components/Smart/Pagination';
import Page404 from 'Components/ErrorPages/404';
import Breadcrumbs from 'Components/Dumb/Breadcrumbs';
import Loader from 'Components/Loader';
import Filters from 'Components/Smart/Filters';

import generateAdvertisementsPayload from 'utils/generateAdvertisementsPayload';
import { useScroll } from 'utils/ScrollProvider';
import { getRouterBasePath, getMetadataWithLocation } from 'utils/meta';
import jsonSafeParse from 'utils/jsonSafeParse';
import { convertCapitalize, Cookie } from 'utils/handlers';
import sortByTranslation from 'utils/sortByTranslation';
import { replacePlaceholders } from 'utils/config';

import {
  deviceParams,
  getUserGeolocation,
  getSelectedCategory,
  getSelectedSubCategory,
  getUrlStructure,
} from 'store/reselect';
import { setSelectedSettlement } from 'store/actions';

import API from 'services/api';

import NoContent from './NoContent';
import ListingItem from './ListingItem';
import { generateBreadcrumbsPath } from './config';

const Listing = props => {
  const t = useTranslations();
  const router = useRouter();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [labels, setLabels] = useState(props.labels || []);
  const [listingData, setListingData] = useState(props.categoryInfo || []);
  const [listingCount, setListingCount] = useState(props.categoryCount || '');
  const [selectedFilters, setSelectedFilters] = useState(
    props.selectedFilters || {},
  );

  const {
    deviceType,
    urlStructure,
    userGeolocation,
    selectedCategory,
    selectedSubCategory,
  } = useMemoSelector(store => ({
    userGeolocation: getUserGeolocation(store),
    deviceType: deviceParams()(store).deviceType,
    urlStructure: getUrlStructure(store),
    selectedCategory: getSelectedCategory(store, router.query.slug),
    selectedSubCategory: getSelectedSubCategory(store, router.query.slug),
  }));

  const {
    isValidUrl,
    isAvailableNow,
    label_ids = [],
    category_id: selectedCategoryId,
  } = urlStructure;

  const {
    ref: parentRef,
    updateData,
    setSelectItem,
  } = useScroll({ deps: [listingData?.length] });

  const locationData = userGeolocation || Cookie.getCookieByKey('location');
  const userLocation =
    userGeolocation || (locationData && jsonSafeParse(locationData));

  const { title: labelTitle } = selectedSubCategory || {};

  const needToShowLabels = !!labels?.length && !isAvailableNow;

  const page = router.query.page ? router.query.page - 1 : 0;

  const generateListingPayload = useCallback(() => {
    if (!Object.keys(urlStructure).length) return;

    const payload = generateAdvertisementsPayload({
      ...urlStructure,
      userLocation,
      term: router.query.term,
      distance: router.query.distance,
    });

    return payload;
  }, [urlStructure, userLocation, router.query.term, router.query.distance]);

  const getData = useCallback(
    async offset => {
      try {
        if (!Object.keys(urlStructure).length) return;

        setIsLoading(true);

        if (isAvailableNow) {
          const { data: { result = [], count } = {} } =
            await API.fetchDynamicAggregatorAds(
              'available-now',
              offset * 30,
              30,
            );

          setListingData(result);
          setListingCount(count);
        } else {
          const payload = generateListingPayload();

          if (!isValidUrl) {
            throw new Error();
          }

          const filters = {
            ...selectedFilters,
            ...payload,
          };

          const {
            data: { result, count },
          } = await API.fetchAdListing({
            offset: offset * 30,
            limit: 30,
            filters,
          });
          setListingData(result);
          setListingCount(count);
        }

        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      urlStructure,
      isValidUrl,
      selectedFilters,
      router.query.term,
      router.query.distance,
    ],
  );

  useMount(() => () => dispatch(setSelectedSettlement('')));

  useMount(() => () => setLabels([]));

  useEffect(() => {
    (async () => {
      const {
        data: { labels },
      } = await API.fetchAdFilters({
        types: 'labels',
        category_id: selectedCategoryId,
      });

      setLabels(sortByTranslation({ data: labels, key: 'label' }, t));

      if (router.query.selectedFilters) {
        const {
          data: { result },
        } = await API.gatAdSelectedFilters(router.query.selectedFilters);

        setSelectedFilters(result);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategoryId, router.query.selectedFilters]);

  useEffect(() => {
    getData(page);
  }, [getData, page]);

  const replaceableValues = {
    AD_COUNT: listingCount,
    CITY: urlStructure.selectedCity,
    PROVINCE: convertCapitalize(urlStructure.selectedProvince),
    LABEL: labelTitle ? t(labelTitle) : '',
    CATEGORY: selectedCategory ? t(selectedCategory.title) : '',
    TERM: urlStructure.term,
    NEARBY: t('nearby'),
    RESULT: t('result'),
    FOR: t('for'),
    IN: 'in',
  };
  const pageHeading = urlStructure.heading
    ? replacePlaceholders(urlStructure.heading, replaceableValues)
    : '';

  const { metaTitle, metaDescription } =
    getMetadataWithLocation(urlStructure?.metaData) || {};

  const { title, description } = getRouterBasePath(router.asPath);

  const searchResult = pageHeading && (
    <MySJSimpleTitleBar>
      <div className="simple-bar_title">
        <h1 className="title-content">{pageHeading}</h1>
      </div>
    </MySJSimpleTitleBar>
  );

  if (Object.keys(urlStructure).length && !isValidUrl) {
    return <Page404 />;
  }

  const noAdView = isLoading ? <Loader /> : <NoContent />;

  return (
    <div ref={parentRef}>
      <Canonical />
      <NextSeo
        title={metaTitle || title}
        description={metaDescription || description}
        noindex={!!router.query.settlement}
      />
      <Container>
        <Breadcrumbs
          roadMap={{
            defaultPath: 'home',
            morePaths: {
              ...generateBreadcrumbsPath(
                router.query.slug,
                selectedCategory,
                selectedSubCategory,
              ),
            },
          }}
        />
        <Row>
          <Col>
            {deviceType !== 'mobile' && searchResult}
            {isAvailableNow && (
              <MySJSimpleTitleBar>
                <div className="simple-bar_title" data-intro="secondary-bar">
                  <h4 className="title-content">
                    {t('available_now_listing_title')}
                  </h4>
                </div>
              </MySJSimpleTitleBar>
            )}

            {needToShowLabels && (
              <LabelPicker labels={labels} currentLabelId={label_ids[0]} />
            )}
            <Filters generateListingPayload={generateListingPayload} />
            {deviceType === 'mobile' && searchResult}
            {listingData?.length
              ? listingData.map((ad, index) => (
                  <ListingItem
                    ad={ad}
                    index={index}
                    key={ad.reference_id}
                    updateData={updateData}
                    setSelectItem={setSelectItem}
                  />
                ))
              : noAdView}
            {listingCount > 0 && (
              <Pagination
                totalPages={Math.ceil(listingCount / 30)}
                emitPageChange={() => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  });
                }}
              />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Listing;
