import React, {
  useCallback,
  useRef,
  useImperativeHandle,
  useEffect,
  useState,
} from 'react';
import cookie from 'js-cookie';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslations } from 'next-intl';

import { InlineToast, SimpleInput, Select } from 'Components/Shared/UI';

import ValidatorsState from 'utils/validators';
import { ifFormIsValid } from 'utils/handlers';
import { TYPE_ACCOUNT_NOTIFY } from 'utils/constants';

import { baseSelector, validators, getLanguage } from 'store/reselect';
import { getAccountConfigs, setLoginByDialog } from 'store/actions';

import API from 'services/api';

import useStyles from './styles';

const Register = React.forwardRef(
  ({ mounted, triggerModalOk, emitToActive }, ref) => {
    const t = useTranslations();
    const styles = useStyles();
    const dispatch = useDispatch();
    const baseState = useSelector(baseSelector());
    const appLang = useSelector(getLanguage);
    const validatorsPattern = useSelector(validators());

    const [isTermsChecked, setIsTermsChecked] = useState(false);
    const [isPrivacyChecked, setIsPrivaceChecked] = useState(false);

    const accountRoles = baseState?.account_configs?.public_roles?.map(
      item => ({ title: t(`account_type_${item}`), value: item }),
    );
    const validatorsExec = ValidatorsState({
      emailPattern: validatorsPattern.emailPattern,
    });
    const {
      reset,
      watch,
      errors,
      control,
      register,
      setValue,
      formState,
      getValues,
    } = useForm({
      mode: 'onKeypress',
    });
    const password = useRef({});
    password.current = watch('password', '');
    const lang = cookie.get('lang') || appLang;

    useImperativeHandle(ref, () => ({
      onRegister() {
        return new Promise(async (resolve, reject) => {
          try {
            if (document.activeElement) {
              document.activeElement.blur();
            }
            const { password, email, role } = getValues();
            const { data } = await API.createAccount({
              password,
              email,
              role,
              lang,
            });
            if (data) {
              resolve();
            }
          } catch (err) {
            reject(err);
          } finally {
            reset({
              role: '',
            });
          }
        });
      },
    }));

    const goTo = comp => {
      dispatch(setLoginByDialog(comp));
    };

    const triggerOk = evt => {
      if (evt.key === 'Enter') {
        evt.preventDefault();
        triggerModalOk();
      }
    };

    useEffect(() => {
      emitToActive(
        ifFormIsValid(
          ['email', 'password', 'password_repeat', 'role'],
          getValues(),
          errors,
        ) &&
          isTermsChecked &&
          isPrivacyChecked,
      );
    }, [formState]);

    useEffect(() => {
      dispatch(getAccountConfigs());
      mounted();
    }, []);

    const typeAccount = watch('role', '');
    const selectedAccount = useCallback(
      () => (typeAccount ? TYPE_ACCOUNT_NOTIFY[typeAccount] : {}),
      [typeAccount],
    );

    return (
      <div className={styles['register-form--block']}>
        <p>
          {t('register_popup_info_text_1')}
          <span onClick={() => goTo('login')}>
            {t('register_popup_info_text_2')}
          </span>
        </p>
        <form onKeyDown={triggerOk}>
          <SimpleInput
            registerRef={register(validatorsExec.EMAIL_VALIDATOR)}
            value={watch('email')}
            onChange={e => setValue('email', e.target.value)}
            type="email"
            name="email"
            helperText={t('email_helper_text')}
            errors={errors.email?.message}
            label={{ title: t('change_email_input_1'), color: '#000' }}
            fullWidth
            margin={[0, 0, 16, 0]}
            customStylesInput={{ border: '1px solid #D8D8D8' }}
          />
          <SimpleInput
            registerRef={register(validatorsExec.PASSWORD_VALIDATOR)}
            value={watch('password')}
            onChange={e => setValue('password', e.target.value)}
            errors={
              errors.password?.message ||
              (errors.password?.type === 'containsSymbol' &&
                'Use at least one symbol') ||
              (errors.password?.type === 'containsDigit' &&
                'Use 1 ore more numbers') ||
              (errors.password?.type === 'containsUppercaseLowercase' &&
                t('upper_lower_text'))
            }
            name="password"
            helperText={t('register_input_2_info_text')}
            type="password"
            fullWidth
            passwordEye
            customStylesInput={{ border: '1px solid #D8D8D8' }}
            label={{ title: t('change_email_input_2'), color: '#000' }}
            margin={[0, 0, 16, 0]}
          />
          <SimpleInput
            registerRef={register({
              validate: value =>
                value === password.current || t('password_dont_match'),
            })}
            value={watch('password_repeat')}
            onChange={e => setValue('password_repeat', e.target.value)}
            errors={errors.password_repeat?.message}
            name="password_repeat"
            customStylesInput={{ border: '1px solid #D8D8D8' }}
            type="password"
            fullWidth
            label={{ title: t('repeat_password_text'), color: '#000' }}
            margin={[0, 0, 16, 0]}
          />
          <Controller
            as={<Select />}
            margin={[0, 0, 30, 0]}
            defaultValue=""
            control={control}
            name="role"
            options={accountRoles}
            customStylesInput={{ border: '1px solid #D8D8D8' }}
            rules={{
              required: t('mandatory_filed_error'),
            }}
            label={{ title: t('register_input_4'), color: '#000' }}
            errors={errors.role?.message}
            placeholder={t('register_input_4_info_text')}
            size={'md'}
            fullWidth
            popoverZIndex={999999}
          />
          {!!typeAccount && (
            <InlineToast
              title={t(selectedAccount().title)}
              description={t(selectedAccount().description)}
              type="info"
            />
          )}
          <SimpleInput
            theme="dark"
            refBind={register({
              validate: value => !!value,
            })}
            errors={!!(errors.agree_1 && errors.agree_1.type === 'validate')}
            className="register-checkbox"
            name="agree_1"
            type="checkbox"
            onChange={e => setIsTermsChecked(e.target.checked)}
            label={{
              title: t('register_checkbox_1'),
              forId: 'agree1',
              color: 'rgba(0,0,0,0.6)',
            }}
            margin={[0, 0, 16, 0]}
          />
          <SimpleInput
            theme="dark"
            className="register-checkbox"
            refBind={register({
              validate: value => !!value,
            })}
            onChange={e => setIsPrivaceChecked(e.target.checked)}
            errors={!!(errors.agree_2 && errors.agree_2.type === 'validate')}
            name="agree_2"
            type="checkbox"
            label={{
              title: t('register_checkbox_2'),
              forId: 'agree2',
              color: 'rgba(0,0,0,0.6)',
            }}
            margin={[0, 0, 16, 0]}
          />
        </form>
      </div>
    );
  },
);
Register.defaultProps = {
  triggerModalOk: () => void 0,
};
Register.propTypes = {
  mounted: PropTypes.func.isRequired,
  emitToActive: PropTypes.func.isRequired,
  triggerModalOk: PropTypes.func,
};
export default React.memo(Register);
