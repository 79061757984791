import { useTranslations } from 'next-intl';

import useStyles from './styles';

const NoContent = () => {
  const t = useTranslations();
  const classes = useStyles();

  return (
    <div className={classes['no-content-wrapper']}>
      <span className={classes['no-content-text']}>{t('no_ads_info')}</span>
    </div>
  );
};

export default NoContent;
