import { Container, Row, Col } from 'reactstrap';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';

import { getRouterBasePath } from 'utils/meta';

import useStyles from './styles';

const Custom404 = () => {
  const router = useRouter();
  const t = useTranslations();
  const classes = useStyles();

  const { title, description } = getRouterBasePath(router.asPath);
  return (
    <>
      <NextSeo title={title} description={description} />
      <Container>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={{ offset: 1, size: 10 }}
            lg={{ offset: 3, size: 6 }}
            xl={{ offset: 3, size: 6 }}
          >
            <div className={classes['page-404']}>
              <h1>404</h1>
              <div className={classes['page-404__description']}>
                <h3>{t('not_found_page_title')}</h3>
                <p>{t('not_found_page_subtitle')}</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Custom404;
