import { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from 'next-intl';
import { format } from 'date-fns';

import sortByTranslation from 'utils/sortByTranslation';

import useStyles from './styles';

const AdvertisementInfo = ({
  labels,
  isEvent,
  isVacancy,
  referenceId,
  createdDate,
  currentCategoryName,
  adStatistics: {
    view_count: viewCount,
    date_last_bumped: dateLastBumped,
  } = {},
}) => {
  const t = useTranslations();
  const styles = useStyles();

  return (
    <div className="content-with-shadow mb-20 save-bg-on--mobile">
      <div className={styles.root}>
        <h3 className="title-content">{t('ads_detailed_info_title')}</h3>
        <ul>
          <li>
            <span>{t('ads_detailed_info_subtext_1')}</span>
            <p>{t(currentCategoryName)}</p>
          </li>
          {!isEvent && !isVacancy && (
            <li>
              <span>{t('ads_detailed_info_subtext_2')}</span>
              <p>
                {sortByTranslation({ data: labels, key: 'name' }, t)?.map(e => (
                  <span key={e.id}>{e.t_name || e.title?.toUpperCase()}</span>
                ))}
              </p>
            </li>
          )}
        </ul>
        <ul>
          <li>
            <span>{t('ads_detailed_info_subtext_3')}</span>
            {createdDate && (
              <p>{format(new Date(createdDate), 'dd-MM-yyyy')}</p>
            )}
          </li>
          <li>
            <span>{t('ads_detailed_info_subtext_4')}</span>
            {dateLastBumped && (
              <p>{format(new Date(dateLastBumped), 'dd-MM-yyyy')}</p>
            )}
          </li>
          <li>
            <span>{t('ads_detailed_info_subtext_5')}</span>
            <p>{viewCount}</p>
          </li>
          <li>
            <span>{t('ads_detailed_info_subtext_6')}</span>
            <p>{referenceId}</p>
          </li>
        </ul>
      </div>
    </div>
  );
};
AdvertisementInfo.propTypes = {
  info: PropTypes.object,
};
export default memo(AdvertisementInfo);
