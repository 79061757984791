import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';

export default makeStyles({
  root: {
    position: 'relative',
    overflow: ({ deviceType }) =>
      deviceType === 'desktop' ? 'hidden' : 'scroll',
    '& .icon-Chevron---Left, & .icon-Chevron---Right': {
      color: '#fff',
      width: '30px',
      height: '30px',
      top: 20,
      padding: 8,
      cursor: 'pointer',
      borderRadius: '50%',
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      boxShadow: '0 2px 4px 0 rgb(0 0 0 / 30%)',
      backgroundColor: variables.$darkOpacity50,
      '-moz-box-shadow': '0 2px 4px 0 rgb(0 0 0 / 30%)',
      '-webkit-box-shadow': '0 2px 4px 0 rgb(0 0 0 / 30%)',
    },
    '& .icon-Chevron---Left': {
      left: 20,
    },
    '& .icon-Chevron---Right': {
      right: -10,
    },
  },
  selectedFiltersGroup: {
    display: 'flex',
    overflow: ({ deviceType }) =>
      deviceType === 'desktop' ? 'hidden' : 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
});
