import React, { useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles';

const ConfirmEmail = React.forwardRef(({ mounted }, ref) => {
  const styles = useStyles();

  useImperativeHandle(ref, () => ({}));

  useEffect(() => {
    mounted();
  }, []);

  return (
    <div className={styles['confirm-email--block']}>
      <p>Welcome to Sexjobs.nl!</p>
      <p>An email is on its way with a link to activate your account.</p>
      <p>
        If you have not received an e-mail after a while, please check the spam
        box (unwanted messages) of your e-mail program. It's probably in there.
      </p>
      <p>
        If you create an account you automatically agree with the advertising
        rules, the code of conduct and the general terms and conditions as they
        apply to sexjobs.
      </p>
      <p>
        We reserve the right to decline and close accounts to increase security
        and reliability! Also read: Tips for dating safely | Sex jobs, police &
        justice
      </p>
      <p>
        Issues? If you still run into problems, please check our FAQ page. Here
        are answers to most questions. Are you still not sure or do you want to
        give feedback? Send a message via our contact form.
      </p>
      <p>
        After activation you are immediately logged in and you can start placing
        your ads.
      </p>
      <p>Have fun and especially good luck at sexjobs.nl!</p>
      <p>Team Sexjobs</p>
    </div>
  );
});
ConfirmEmail.defaultProps = {};
ConfirmEmail.propTypes = {
  mounted: PropTypes.func.isRequired,
};
export default React.memo(ConfirmEmail);
