import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';
import { Shadow, MediaQuery } from 'static/styles/jss/abstracts/mixins';

export default makeStyles({
  badge__block: {
    borderRadius: '3px',
    display: ({ isMobile }) => (isMobile ? 'flex' : 'grid'),
    cursor: 'pointer',
    gridTemplateColumns: 'auto 1fr auto auto',
    backgroundColor: variables.$filters.$headerBG,
    ...Shadow('0 3px 6px 0 rgba(0,0,0,0.15)'),
    marginBottom: '16px',
    '& .image-wrapper': {
      position: 'relative',
      '& .cover-photo': {
        borderRadius: '3px 0 0 3px',
        ...MediaQuery.down({
          flex: 1,
          flexShrink: 0,
        }).xs,
        ...MediaQuery.between({
          width: '170px',
          height: '180px',
        }).xss_xs,
      },
      '& .promotion-icon': {
        zIndex: 2,
        position: 'absolute',
        borderRadius: '3%',
        height: '20px',
        top: '0',
        display: 'flex',
        '& span': {
          backgroundColor: 'inherit',
        },
      },
    },
  },
  'details--block-section__bt': {
    '& p': {
      margin: '0 0 5px',
      color: variables.$greyTxtColor,
      fontSize: '13px',
      '& span': {
        marginRight: '6px',
      },
    },
  },
  'details--block': {
    padding: '9px 210px 9px 20px',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'space-between',
    ...MediaQuery.down({
      paddingRight: '20px',
    }).lg,
    ...MediaQuery.down({
      padding: '5px 10px 5px 10px',
      fontSize: '11px',
      flex: 1,
    }).xs,
    '& .details--block-activation, & .details--block-location': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100px',
      fontSize: '13px',
      textAlign: 'center',
      color: variables.$helperTextColor,
      borderLeft: `1px solid ${variables.$baseColorDark_30}`,
      position: 'absolute',
      top: '12px',
      right: 0,
      height: '90%',
      ...MediaQuery.down({
        fontSize: '11px',
      }).xss,
      '& > span': {
        marginBottom: '7px',
        ...MediaQuery.down({
          marginBottom: '3px',
        }).xss,
      },
      ...MediaQuery.down({
        position: 'initial',
        borderLeft: 'none',
        height: 'auto',
        flexDirection: 'revert',
        alignItems: 'baseline',
        width: 'auto',
        textAlign: 'left',
        justifyContent: 'initial',
        '& br': {
          display: 'none',
        },
        '& .icon-Time, & .icon-Locatie': {
          marginTop: '0 !important',
          marginRight: '10px',
        },
      }).lg,
    },
    '& .details--block-activation': {
      right: '100px',
      ...MediaQuery.down({
        display: 'none',
      }).xss,
    },
    '& .title-content': {
      marginBottom: '7px',
      display: 'grid',
      justifyContent: 'flex-start',
      ...MediaQuery.down({
        fontSize: '13px',
      }).xss,
      '& > b': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '100%',
      },
      ...MediaQuery.down({
        gap: '7px',
        '& > b': {
          gridColumn: '1 / -1',
        },
        '& .sticker__tile': {
          margin: '0 !important',
          gridColumn: 'span 2',
          width: 'fit-content',
        },
      }).lg,
      ...MediaQuery.down({
        gap: '2px',
        fontSize: '13px',
        marginBottom: '5px',
      }).xss,
    },
    '& .gender-type__block, & .search-type__block': {
      marginBottom: '5px',
      color: variables.$helperTextColor,
      fontSize: '13px',
      display: 'flex',
      alignItems: 'center',
      ...MediaQuery.down({
        marginBottom: '3px',
        fontSize: '11px',
      }).xss,
      '& img, & span, & svg': {
        maxWidth: '13px',
        marginRight: '10px',
      },
    },
    '& .labels__block': {
      margin: 0,
      position: 'relative',
      display: 'flex',
      width: '100%',
      ...MediaQuery.down({
        width: '160px',
      }).xss,
      ...MediaQuery.down({
        width: '200px',
      }).xs,
      overflowY: 'hidden',
      '& > span': {
        whiteSpace: 'nowrap',
        marginRight: '5px',
        borderRadius: '2px',
        backgroundColor: '#094287',
        color: '#98A4B3',
        fontSize: '11px',
        padding: '5px 5px',
      },
    },
    '& .description__block': {
      ...MediaQuery.down({
        display: 'none',
      }).lg,
      wordBreak: 'break-word',
      color: variables.$helperTextColor,
      fontSize: '13px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': '4',
      '-webkit-box-orient': 'vertical',
      marginBottom: '5px',
    },
  },
});
