import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';

import browserDetect from 'utils/browserDetect';

export default makeStyles({
  filterContent: {
    height: '100vh',
    backgroundColor: variables.$darkOpacity50,
    width: ({ deviceType }) => (deviceType === 'mobile' ? '100%' : '376px'),
    '& .content': {
      padding: ({ deviceType }) =>
        `0 17px ${
          deviceType === 'mobile'
            ? browserDetect() === 'chrome'
              ? '240px'
              : '210px'
            : '140px'
        }  17px`,
      height: '100%',
      overflow: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',
    },
    '& .arrow, & .clear-all': {
      cursor: 'pointer',
    },
  },
});
