import React, { useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useTranslations } from 'next-intl';

import { SimpleInput } from 'Components/Shared/UI';

import ValidatorsState from 'utils/validators';
import { ifFormIsValid } from 'utils/handlers';

import { validators } from 'store/reselect';
import { setLoginByDialog } from 'store/actions';

import axios from 'services/axiosEnv';

import useStyles from './styles';

const ForgotPassword = React.forwardRef(
  ({ mounted, triggerModalOk, emitToActive }, ref) => {
    const t = useTranslations();
    const styles = useStyles();
    const dispatch = useDispatch();
    const validatorsPattern = useSelector(validators());
    const validatorsExec = ValidatorsState({
      emailPattern: validatorsPattern.emailPattern,
    });

    const { register, errors, reset, formState, getValues, watch, setValue } =
      useForm({
        mode: 'onKeypress',
      });

    useImperativeHandle(ref, () => ({
      onSend() {
        return new Promise(async (resolve, reject) => {
          try {
            if (document.activeElement) {
              document.activeElement.blur();
            }
            const { email } = getValues();
            const { data } = await axios.post('/account/passwords/recover', {
              email,
            });
            if (data) {
              resolve();
              toast(t('reset_password_success_text'), {
                type: 'dark',
                autoClose: 3000,
              });
            }
          } catch (err) {
            reset();
            reject(err);
          }
        });
      },
    }));

    const triggerOk = evt => {
      if (evt.key === 'Enter') {
        evt.preventDefault();
        triggerModalOk();
      }
    };

    useEffect(() => {
      emitToActive(ifFormIsValid(['email'], getValues(), errors));
    }, [formState]);

    useEffect(() => {
      mounted();
    }, []);

    return (
      <div className={styles['forgot-password--block']}>
        <p>
          {t('forgot_password_info_text_1')}
          <span onClick={() => dispatch(setLoginByDialog('login'))}>
            {t('forgot_password_info_text_2')}
          </span>
        </p>
        <form onKeyDown={triggerOk}>
          <SimpleInput
            fullWidth
            type="email"
            name="email"
            margin={[0, 0, 16, 0]}
            value={watch('email')}
            errors={errors.email && errors.email.message}
            registerRef={register(validatorsExec.EMAIL_VALIDATOR)}
            label={{ title: t('change_email_input_1'), color: '#000' }}
            onChange={e => setValue('email', e.target.value)}
            customStylesInput={{ border: '1px solid #D8D8D8' }}
          />
        </form>
      </div>
    );
  },
);
ForgotPassword.defaultProps = {
  triggerModalOk: () => void 0,
};
ForgotPassword.propTypes = {
  mounted: PropTypes.func.isRequired,
  emitToActive: PropTypes.func.isRequired,
  triggerModalOk: PropTypes.func,
};
export default React.memo(ForgotPassword);
