import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  filterButtons: {
    display: 'flex',
    marginBottom: '15px',
  },
  filterButton: {
    color: 'white',
    border: 'none',
    fontSize: '13px',
    margin: '0 5px',
    width: '62px',
    height: '42px',
    borderRadius: '21px',
    backgroundColor: '#3378D1',
  },
  selectedFilterButton: {
    padding: '0 16px',
    textWrap: 'nowrap',
    whiteSpace: 'nowrap',
    margin: '0 5px',
    color: 'white',
    fontSize: '13px',
    height: '42px',
    borderRadius: '21px',
    border: '1px solid #3378D1',
    background: 'transparent',
  },
});
