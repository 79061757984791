import { useState, useEffect } from 'react';
import useMemoSelector from 'hooks/useMemoSelector';

import { deviceParams } from 'store/reselect';

import useStyles from './styles';

const Slider = ({ children }) => {
  const [clientWidth, setClientWidth] = useState(0);
  const { deviceType } = useMemoSelector(deviceParams());
  const [scrollPosition, setScrollPosition] = useState(0);
  const [filterGroupNode, setFilterGroupNode] = useState(null);
  const { root, selectedFiltersGroup } = useStyles({ deviceType });

  useEffect(() => {
    if (filterGroupNode) {
      filterGroupNode.scrollTo({
        top: 0,
        behavior: 'smooth',
        left: scrollPosition,
      });
    }
  }, [filterGroupNode, scrollPosition]);

  return (
    <div className={root}>
      {deviceType === 'desktop' && (
        <>
          {scrollPosition > 0 && (
            <span
              className="icon-Chevron---Left"
              onClick={() =>
                setScrollPosition(
                  filterGroupNode?.scrollLeft -
                    filterGroupNode?.scrollWidth / 10,
                )
              }
            />
          )}

          {scrollPosition + clientWidth < filterGroupNode?.scrollWidth && (
            <span
              className="icon-Chevron---Right"
              onClick={() =>
                setScrollPosition(
                  filterGroupNode?.scrollLeft +
                    filterGroupNode?.scrollWidth / 10,
                )
              }
            />
          )}
        </>
      )}
      <div
        ref={node => {
          if (node) {
            setFilterGroupNode(node);
            setClientWidth(node.clientWidth);
          }
        }}
        className={selectedFiltersGroup}
      >
        {children}
      </div>
    </div>
  );
};

export default Slider;
