import { useState, useCallback, useRef, useEffect } from 'react';
import useMount from 'hooks/use-mount';
import { useRouter } from 'next/router';
import useMemoSelector from 'hooks/useMemoSelector';
import useLockBodyScroll from 'hooks/useLockBodyScroll';

import AsideMenu from 'Components/AsideMenu';

import mergeRecursive from 'utils/mergeRecursive';
import isEmpty from 'utils/isEmpty';
import {
  getSelectedGenders,
  updateFiltersState,
  getFilteredAppearance,
  getFiltersWithTrueValue,
} from 'utils/filters';

import { getSelectedCategory } from 'store/reselect';
import { deviceParams } from 'store/reselect';

import API from 'services/api';

import Header from './Header';
import Footer from './Footer';
import useStyles from './styles';
import FilterItem from './FilterItem';

const MainFilters = ({
  selectedFilterRef,
  handleCloseFilters,
  generateListingPayload,
}) => {
  const router = useRouter();
  const currentFiltersState = useRef(null);

  const { deviceType, selectedCategory } = useMemoSelector(store => ({
    deviceType: deviceParams()(store).deviceType,
    selectedCategory: getSelectedCategory(store, router.query.slug),
  }));
  const [filterState, setFilterState] = useState({});
  const [categoryCount, setCategoryCount] = useState();

  const { filterContent } = useStyles({ deviceType });

  useLockBodyScroll();

  useMount(() => {
    (async () => {
      const {
        data: { filters },
      } = await API.fetchAdFilters({
        category_id: Number(selectedCategory?.id),
      });

      const {
        data: { result },
      } = await API.gatAdSelectedFilters(router.query.selectedFilters);

      setFilterState(mergeRecursive(filters, result));
      currentFiltersState.current = filters;
    })();
  });

  useMount(() => {
    if (selectedFilterRef.current) {
      const timerId = setTimeout(() => {
        const targetNode = document.querySelector(
          `[data-scroll-id="${selectedFilterRef.current}"]`,
        );
        targetNode?.scrollIntoView({ behavior: 'smooth' });
      }, 800);

      return () => {
        selectedFilterRef.current = null;
        clearTimeout(timerId);
      };
    }
  });

  useEffect(() => {
    if (!isEmpty(filterState)) {
      (async () => {
        const payload = generateListingPayload();

        const filters = {
          ...payload,
          ...getFiltersWithTrueValue(filterState),
        };

        const {
          data: { count },
        } = await API.fetchAdListing({ filters, limit: 1, offset: 0 });

        setCategoryCount(count);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState]);

  const handleFilterChange = useCallback(
    ({ key, value, label, groupName, section }) =>
      setFilterState(prevFilterState =>
        updateFiltersState({
          key,
          label,
          value,
          section,
          groupName,
          state: prevFilterState,
        }),
      ),
    [],
  );

  const handleResetFilters = useCallback(() => {
    setFilterState(currentFiltersState.current);
  }, []);

  const handleApplyFilters = useCallback(async () => {
    const { data } = await API.setAdSelectedFilters({
      filters: getFiltersWithTrueValue(filterState),
    });

    router.query.selectedFilters = data.hash;
    router.push(router);

    handleCloseFilters();
  }, [filterState, handleCloseFilters, router]);

  const { isSelectedFemale, isSelectedMale } = getSelectedGenders(filterState);

  return (
    <AsideMenu>
      <div className={filterContent}>
        <Header
          handleCloseFilters={handleCloseFilters}
          handleResetFilters={handleResetFilters}
        />
        <div className="content">
          {Object.keys(filterState).map(title => (
            <FilterItem
              key={title}
              title={title}
              data={getFilteredAppearance({
                title,
                isSelectedMale,
                isSelectedFemale,
                filters: filterState[title],
              })}
              handleFilterChange={handleFilterChange}
            />
          ))}
        </div>
        <Footer
          categoryCount={categoryCount}
          handleApplyFilters={handleApplyFilters}
        />
      </div>
    </AsideMenu>
  );
};

export default MainFilters;
