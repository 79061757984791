import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';

export default makeStyles({
  extend_arrows: {
    padding: '10px',
    display: 'inline-flex',
    cursor: 'pointer',
    color: '#fff',
    fontSize: '10px',
    height: '42px',
    alignItems: 'center',
    justifyContent: 'center',
    '&.disabled-btn': {
      pointerEvents: 'none',
      '&::before': {
        opacity: 0.2,
      },
    },
  },
  'pagination-container': {
    userSelect: 'none',
    margin: '20px 0',
    textAlign: 'center',
    '& .pagination-link-item': {
      borderRight: '1px solid #11509F',
      '&:last-child': {
        borderRight: 'none',
      },
    },
  },
  'pagination-wrapper': {
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: variables.$regularColor,
    borderRadius: '3px',
    '& .icon-Chevron---Left': {
      borderRight: '1px solid #11509F',
    },
    '& .icon-Chevron---Right': {
      borderLeft: '1px solid #11509F',
    },
    '& .icon-Chevron---Right, & .icon-Chevron---Left': {
      padding: '10px',
      display: 'inline-flex',
      cursor: 'pointer',
      color: '#fff',
      fontSize: '10px',
      height: '42px',
      alignItems: 'center',
      justifyContent: 'center',
      '&.disabled-btn': {
        pointerEvents: 'none',
        '&::before': {
          opacity: 0.2,
        },
      },
    },
  },
  'pagination-item': {
    height: '16px',
    padding: '0 10px',
    cursor: 'pointer',
    color: '#C3CBD5',
    minWidth: '36px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  'pagination-item__active': {
    color: '#FFF200',
  },
});
