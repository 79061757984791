import React, { useImperativeHandle } from 'react';

import { Avatar } from 'Components/Shared/UI';

import useStyles from './styles';

const ChooseAd = React.forwardRef(({}, ref) => {
  const styles = useStyles();

  useImperativeHandle(ref, () => ({}));
  const ads = [
    {
      id: 1,
      avatar: '/images/profileAvatar.jpg',
      name: 'Real Playgirl - B(I)ong',
      category: 'Dames Van plezier',
    },
    {
      id: 2,
      avatar: '',
      name: 'Real Playgirl - B(I)ong',
      category: 'Dames Van plezier',
    },
    {
      id: 3,
      avatar: '/images/profileAvatar.jpg',
      name: 'Real Playgirl - B(I)ong',
      category: 'Dames Van plezier',
    },
    {
      id: 4,
      avatar: '/images/profileAvatar.jpg',
      name: 'Real Playgirl - B(I)ong',
      category: 'Dames Van plezier',
    },
  ];

  return (
    <div className={styles['choose-ad--block']}>
      <p className="choose-ad-price-tag">€ 14,95 per day</p>
      <b>Choose an advertisement to apply the product to.</b>
      <ul>
        {ads.map(ad => (
          <li key={ad.id}>
            <Avatar
              name={ad.name}
              width={33}
              height={33}
              radius={4}
              avatarSrc={ad.avatar}
              alt=""
            />
            <div className={styles['item--block']}>
              <h3>{ad.name}</h3>
              <p>{ad.category}</p>
            </div>
            <span className="icon-Chevron---Right"></span>
          </li>
        ))}
      </ul>
    </div>
  );
});
ChooseAd.defaultProps = {};
ChooseAd.propTypes = {};
export default React.memo(ChooseAd);
