import React, { useImperativeHandle } from 'react';
import { toast } from 'react-toastify';
import useMount from 'hooks/use-mount';
import { useTranslations } from 'next-intl';

import { Input } from 'Components/Shared/UI';

import API from 'services/api';

import useStyles from './styles';

const SendMessage = React.forwardRef(
  ({ emitToActive, mounted, triggerModalOk, message, setMessage }, ref) => {
    const t = useTranslations();
    const styles = useStyles();

    useImperativeHandle(ref, () => ({
      sendMessage: async messageInfo => {
        const { data } = await API.createMessage(messageInfo);

        setMessage('');

        data &&
          toast(t('message_sent_text'), {
            type: 'dark',
            autoClose: 3000,
          });
      },
    }));

    const triggerOk = evt => {
      if (evt.key === 'Enter' && !evt.shiftKey) {
        evt.preventDefault();
        triggerModalOk();
      }
      setMessage(evt.target.value);
    };

    useMount(() => {
      mounted();
    });

    return (
      <div className={styles['send-message--block']}>
        <p>
          <span className="icon-Chat"></span>
          {/* static text */}
          {t('generally_responds_mock_text')}
        </p>
        <Input
          value={message}
          events={['input', 'keydown']}
          onKeydown={triggerOk}
          onInput={evt => {
            setMessage(evt.target.value);
            emitToActive(!!evt.target.value);
          }}
          textArea={{ rows: 5 }}
          label={{ title: t('type_message_placeholder'), color: '#000' }}
          fullWidth
          placeholder={t('enter_message_placeholder')}
          margin={[0, 0, 16, 0]}
          customStylesInput={{ border: '1px solid #D8D8D8' }}
        />
      </div>
    );
  },
);

export default React.memo(SendMessage);
