import { useCallback, useMemo, useEffect, useState, memo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import useStyles from './styles';

const Pagination = ({
  totalPages,
  showPagesCount,
  allowDots,
  activePage,
  emitPageChange,
}) => {
  const router = useRouter();
  const styles = useStyles();

  const [activeIndex, setActiveIndex] = useState(
    (router.query.page || activePage) - 1,
  );

  const turnPage = useCallback(
    dir => {
      setActiveIndex(prevState => {
        const isLeftDir = dir === 'Left';
        const leftDir = prevState > 0 ? prevState - 1 : 0;
        const rightDir =
          prevState === totalPages - 1 ? totalPages - 1 : prevState + 1;

        const result = isLeftDir ? leftDir : rightDir;

        const currentPage = Number(router.query.page) || 1;

        emitPageChange?.();

        router.push({
          query: {
            ...router.query,
            page: isLeftDir ? currentPage - 1 : currentPage + 1,
          },
        });

        return result;
      });
    },
    [router, totalPages, emitPageChange],
  );

  const arrows = useCallback(
    dir => (
      <span
        className={`icon-Chevron---${dir} ${
          activeIndex === (dir === 'Left' ? 0 : totalPages - 1)
            ? 'disabled-btn'
            : ''
        }`}
        onClick={() => turnPage(dir)}
      />
    ),
    [activeIndex, totalPages, turnPage],
  );

  const goToPage = useCallback(
    (itemIndex, indexArray, pages) => {
      if (pages[indexArray] === '...') {
        const betweenPageSize =
          showPagesCount -
          2 -
          (allowDots && showPagesCount < totalPages ? 1 : 0);
        if (indexArray + 1 === pages.length - 1) {
          setActiveIndex(activeIndex + betweenPageSize);
          router.push({
            query: { ...router.query, page: activeIndex + betweenPageSize + 1 },
          });
        }
        if (indexArray === 1) {
          setActiveIndex(activeIndex - betweenPageSize);
          router.push({
            query: { ...router.query, page: activeIndex - betweenPageSize + 1 },
          });
        }
      } else {
        setActiveIndex(itemIndex);
      }
      emitPageChange?.();
    },
    [
      activeIndex,
      showPagesCount,
      allowDots,
      totalPages,
      router,
      emitPageChange,
    ],
  );

  useEffect(() => {
    if (Number(router.query.page) === 1 && activeIndex !== 0) {
      setActiveIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query.page]);

  const pages = useMemo(() => {
    const _showPagesCount =
      showPagesCount - 2 - (allowDots && showPagesCount < totalPages ? 1 : 0);

    let currentPage = activeIndex + 1;
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages - 1) {
      currentPage = totalPages - 1;
    }

    let startPage;
    let endPage;
    if (totalPages <= showPagesCount) {
      // total pages less than max so show all pages
      startPage = 1;
      endPage = totalPages;
    } else {
      // total pages more than max so calculate start and end pages
      const maxPagesBeforeCurrentPage = Math.floor(_showPagesCount / 2);
      const maxPagesAfterCurrentPage = Math.ceil(_showPagesCount / 2) - 1;
      startPage = currentPage - maxPagesBeforeCurrentPage;
      endPage = currentPage + maxPagesAfterCurrentPage;
    }
    if (currentPage <= _showPagesCount) {
      startPage = 2;
      endPage = startPage + _showPagesCount - 1;
    } else if (currentPage >= totalPages - 1) {
      endPage = totalPages - 1;
      startPage = endPage - _showPagesCount + 1;
    } else if (_showPagesCount === 2 && currentPage > 1) {
      startPage = currentPage;
      endPage = startPage + 1;
    }
    let pages;
    if (totalPages < showPagesCount) {
      pages = Array.from(Array(totalPages).keys()).map(i => i + 1);
    } else {
      pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
        i => startPage + i,
      );
      if (allowDots) {
        if (pages[0] !== 2) {
          pages.unshift('...');
        }
        if (pages.slice(-1)[0] !== totalPages - 1) {
          pages.push('...');
        }
      }
      pages.unshift(1);
      pages.push(totalPages);
    }

    return (
      <div className={styles['pagination-pages--block']}>
        {pages.map((i, index) => {
          const [currentPath, existingQuery] = router.asPath.split('?');

          let currentQuery = '';

          if (i !== 1) {
            currentQuery = existingQuery?.replace(/(&|\?)*page=\d+/g, '') || '';
            currentQuery += currentQuery ? `&page=${i}` : `?page=${i}`;
          } else {
            currentQuery = existingQuery?.replace(/(&|\?)*page=\d+/g, '') || '';
          }

          const content = (
            <span
              key={i + index}
              onClick={() => goToPage(i - 1, index, pages)}
              className={`${
                activeIndex === i - 1 ? styles['pagination-item__active'] : ''
              } ${styles['pagination-item']}`}
            >
              {i}
            </span>
          );

          const path = currentQuery
            ? `${currentPath}?${currentQuery.replace('?', '')}`
            : currentPath;

          return i !== '...' ? (
            <Link key={i + index} href={path}>
              <a className="pagination-link-item">{content}</a>
            </Link>
          ) : (
            content
          );
        })}
      </div>
    );
  }, [
    activeIndex,
    allowDots,
    goToPage,
    router.asPath,
    showPagesCount,
    styles,
    totalPages,
  ]);

  return (
    <div className={styles['pagination-container']}>
      <div className={styles['pagination-wrapper']}>
        {arrows('Left')}
        {pages}
        {arrows('Right')}
      </div>
    </div>
  );
};

Pagination.defaultProps = {
  showPagesCount: 6,
  allowDots: true,
  activePage: 1,
  emitPageChange: () => void 0,
};

export default memo(Pagination);
