import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';
import {
  MediaQuery,
  Shadow,
  TitleInsideMainContent,
} from 'static/styles/jss/abstracts/mixins';

export default makeStyles({
  // Verification Page
  'verification-page': {
    ...MediaQuery.up({
      backgroundColor: variables.$baseColorLight_30,
      ...Shadow('0 3px 6px 0 rgba(0,0,0,0.15)'),
    }).sm,
    marginBottom: '25px',
    padding: '3vw 2vw',
  },
  'notify-text': {
    marginTop: '20px',
  },
  'section-one_id-v': {
    marginBottom: '30px',
  },
  'section-two_id-v': {
    marginBottom: '30px',
    '& .title-content': {
      margin: '36px 0 16px',
      fontSize: '20px',
    },
  },
  'check-list--block': {
    listStyleType: 'none',
    padding: '0',
    '& li': {
      position: 'relative',
      paddingLeft: '20px',
      color: '#fff',
      fontSize: '13px',
      '&::before': {
        content: '""',
        top: '3px',
        left: '4px',
        width: '6px',
        height: '12px',
        border: 'solid white',
        borderWidth: '0 2px 2px 0',
        '-webkit-transform': 'rotate(45deg)',
        '-ms-transform': 'rotate(45deg)',
        transform: 'rotate(45deg)',
        position: 'absolute',
        zIndex: '2',
      },
    },
  },
  'section-three_id-v': {},
  'section-four_id-v': {
    '& .birth-of-date_id-v': {
      display: 'flex',
    },
  },
  'section-five_id-v': {
    marginBottom: '30px',
    marginTop: '10px',
    '& .birth-of-date_id-v': {
      display: 'flex',
    },
  },
  'section-six_id-v': {
    '& .title-content': {
      margin: '36px 0 16px',
      fontSize: '20px',
    },
  },
  'section-seven_id-v': {
    '& .title-content': {
      margin: '36px 0 16px',
      fontSize: '20px',
    },
  },
  'section-eight_id-v': {
    '& .title-content': {
      margin: '36px 0 16px',
      fontSize: '20px',
    },
  },
  // ---------------

  // Forgot Password Page
  'forgot-pass_page': {
    ...MediaQuery.up({
      backgroundColor: variables.$baseColorLight_30,
      ...Shadow('0 3px 6px 0 rgba(0,0,0,0.15)'),
    }).sm,
    marginTop: '25px',
    marginBottom: '25px',
    padding: '40px 5vw',
    ...MediaQuery.down({
      padding: '40px 0px',
    }).sm,
    '& .forgot-pass_page--title': {
      ...TitleInsideMainContent({
        fontSize: '25px',
        marginBottom: '26px',
      }),
    },
  },
  // Login Page
  'login-page': {
    marginTop: '25px',
    marginBottom: '25px',
    ...MediaQuery.up({
      backgroundColor: variables.$baseColorLight_30,
      ...Shadow('0 3px 6px 0 rgba(0,0,0,0.15)'),
    }).sm,
  },
  'login-page-form--block': {
    padding: '40px 5vw',
    ...MediaQuery.down({
      padding: '40px 0px',
    }).sm,
    '& a': {
      color: '#fff',
    },
  },
  // Register Page
  'register-page': {
    marginTop: '25px',
    marginBottom: '25px',
    ...MediaQuery.up({
      backgroundColor: variables.$baseColorLight_30,
      ...Shadow('0 3px 6px 0 rgba(0,0,0,0.15)'),
    }).sm,
  },
  'register-page-form--block': {
    padding: '40px 5vw',
    ...MediaQuery.down({
      padding: '40px 0px',
    }).sm,
    '& .congratulates-container': {
      '& .issues': {
        '& b': {
          color: 'rgb(255, 255, 255)',
          fontSize: '13px',
        },
      },
      '& p': {
        color: 'rgb(255, 255, 255)',
        fontSize: '13px',
        '& a': {
          color: '#fff',
          textDecoration: 'underline !important',
          cursor: 'pointer',
        },
      },
    },
  },
  // My SexJobs Page
  'links-container-of-sex-jobs': {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '-5px',
    marginRight: '-5px',
    '& .link-item': {
      cursor: 'pointer',
      margin: '5px',
      backgroundColor: variables.$baseColorLight_30,
      ...Shadow('0 3px 6px 0 rgba(0,0,0,0.15)'),
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      width: 'calc(25% - 10px)',
      height: '200px',
      color: '#fff',
      fontWeight: 600,
      transition: '.4s',
      ...MediaQuery.between({
        width: 'calc(33.33333% - 10px)',
        height: '180px',
      }).sm_lg,
      ...MediaQuery.down({
        width: 'calc(50% - 10px)',
        height: '200px',
      }).sm,
      ...MediaQuery.down({
        height: '150px',
        fontSize: '12px',
      }).xss,
      '&:hover': {
        ...Shadow('0px 0px 8px 5px rgb(0 0 0 / 15%)'),
      },
      '& span': {
        color: variables.$iconColor,
        fontSize: '28px',
        marginBottom: '10px',
      },
    },
  },
  // Ad view page
  'grid-page-ad-view': {
    // display: 'grid',
    // gridTemplateColumns: '70% 1fr',
    // gridTemplateAreas: '"a b" "c b"',
    '& .grid-area-gallery': {
      gridArea: 'a',
      padding: '0 8px',
    },
    '& .grid-area-advertisers': {
      gridArea: 'b',
      padding: '0 8px',
    },
    '& .grid-area-description': {
      padding: '0 8px',
      gridArea: 'c',
    },
    ...MediaQuery.between({
      gridTemplateColumns: '60% 1fr',
    }).sm_md,
    ...MediaQuery.down({
      gridTemplateAreas: '"a" "b" "c"',
      gridTemplateColumns: '100%',
    }).sm,
  },
});
