import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  'no-content-wrapper': {
    minHeight: '300px',
    position: 'relative',
    top: '70%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  'no-content-text': {
    color: '#fff',
    fontSize: '25px',
    fontWeight: 'bold',
  },
});
