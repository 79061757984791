import React, { useImperativeHandle } from 'react';

import useStyles from './styles';

const CreateAd = React.forwardRef(({}, ref) => {
  const styles = useStyles();

  useImperativeHandle(ref, () => ({}));

  return (
    <div className={styles['create-ad--block']}>
      <p>
        Before you can purchase this product, you need to create an
        advertisement. Please create an advertisement to proceed.
      </p>
    </div>
  );
});
CreateAd.defaultProps = {};
CreateAd.propTypes = {};
export default React.memo(CreateAd);
