import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  header: {
    borderBottom: '1px solid #11509F',
    color: '#fff',
    display: 'flex',
    height: '48px',
    padding: '0 17px',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .title': {
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '18px',
    },
    '& .clear-all': {
      fontSize: '13px',
      fontWeight: 'bold',
      letterSpacing: '0.2px',
      lineHeight: '15px',
    },
  },
});
