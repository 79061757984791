import Link from 'next/link';
import { useTranslations } from 'next-intl';

const GoToProfile = ({ href }) => {
  const t = useTranslations();

  return (
    <div>
      <div>{t('edit-profile-dialog-title')}</div>
      <div>{t('edit-profile-dialog-content')}</div>
      <div>
        {`${t('go_to')} `}
        <Link href={href}>{t('my_profile')}</Link>
      </div>
    </div>
  );
};

export default GoToProfile;
