import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';
import { LabelTag } from 'static/styles/jss/abstracts/extends';

const useStyles = makeStyles({
  root: {
    '& ul': {
      '&:last-child': {
        borderTop: `1px solid ${variables.$dividerColor}`,
        paddingTop: '10px',
      },
      margin: '0',
      paddingLeft: '0px',
      '& li': {
        display: 'flex',
        color: '#fff',
        fontSize: '13px',
        marginBottom: '10px',
        '& > span': {
          minWidth: '100px',
          color: variables.$helperTextColor,
        },
        '& p': {
          margin: 0,
          wordBreak: 'break-word',
          '& span': {
            ...LabelTag,
            lineHeight: 1.2,
            margin: '0 5px 5px 0',
            whiteSpace: 'nowrap',
            display: 'inline-block',
          },
        },
        '&:last-child': {
          borderBottom: '0',
        },
      },
    },
  },
});

export default useStyles;
