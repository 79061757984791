const secondInYear = 31536000;
const secondInMonth = 2592000;
const secondInDays = 86400;
const secondInHours = 3600;
const secondInMinutes = 60;
const minutesInHours = 60;
const hoursInDay = 24;

const getTimePassed = (time, needToSeparate, t) => {
  const dateNow = new Date();
  // @ts-ignore
  const lastDate = new Date(dateNow - time);
  // @ts-ignore
  let diff = Math.abs(dateNow - lastDate) / 1000;

  const year = Math.floor(diff / secondInYear);
  diff -= year * secondInYear;

  const month = Math.floor(diff / secondInMonth);
  diff -= month * secondInMonth;

  const day = Math.floor(diff / secondInDays);
  diff -= day * secondInDays;

  const hour = Math.floor(diff / secondInHours) % hoursInDay;
  diff -= hour * secondInHours;

  const minute = Math.floor(diff / secondInMinutes) % minutesInHours;
  diff -= minute * secondInMinutes;

  const second = Math.floor(diff % secondInMinutes);

  const date = { year, month, day, hour, minute, second };

  const result = Object.entries(date)
    .filter(([, value]) => value > 0)
    .slice(0, 3);

  return needToSeparate
    ? result.map(([timeName, time]) => {
        return `${time} ${time > 1 ? t(`${timeName}s`) : t(timeName)}`;
      }, {})
    : result?.reduce(
        (acc, [timeName, time]) =>
          `${acc} ${time} ${time > 1 ? t(`${timeName}s`) : t(timeName)}`,
        '',
      );
};

export default getTimePassed;
