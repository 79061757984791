import useMemoSelector from 'hooks/useMemoSelector';
import { useTranslations } from 'next-intl';

import { Button } from 'Components/Shared/UI';

import { deviceParams } from 'store/reselect';

import useStyles from './styles';

const Footer = ({ categoryCount, handleApplyFilters }) => {
  const t = useTranslations();
  const { deviceType } = useMemoSelector(deviceParams());
  const { footer } = useStyles({ deviceType });

  return (
    <div className={footer}>
      <Button
        fullWidth
        typeButton="primary"
        onClick={handleApplyFilters}
        text={t('filters_footer_button', { categoryCount })}
      />
    </div>
  );
};

export default Footer;
