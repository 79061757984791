import { useState } from 'react';
import { Collapse } from 'reactstrap';
import { useTranslations } from 'next-intl';
import { MdArrowForwardIos } from 'react-icons/md';
import clsx from 'clsx';

import { SimpleInput } from 'Components/Shared/UI';

import {
  sortableFilters,
  sortFilterByLabel,
  getFiltersWithTrueValue,
} from 'utils/filters';

import useStyles from './styles';

const FilterItemInput = ({
  t,
  name,
  label,
  title,
  value,
  section,
  groupName,
  type = 'checkbox',
  handleFilterChange,
}) => (
  <div data-scroll-id={label} className="filter-item-content">
    <SimpleInput
      type={type}
      checked={value}
      name={name || label}
      label={{
        color: '#fff',
        forId: label,
        title: t(label),
      }}
      onChange={({ target: { checked } }) =>
        handleFilterChange({
          label,
          section,
          groupName,
          key: title,
          value: checked,
        })
      }
    />
  </div>
);

const FilterItem = ({ title, data = [], handleFilterChange }) => {
  const t = useTranslations();
  const [isOpen, setIsOpen] = useState(true);
  const { filterItem } = useStyles({ isOpen });

  const handleCollapseOpen = () => setIsOpen(prevIsOpen => !prevIsOpen);

  const selectedGroup = data.tabs?.find(({ value }) => value);

  const selectedFilters = getFiltersWithTrueValue({ [title]: data });
  const hasSelectedItem = !!selectedFilters[title];

  return (
    <div className={filterItem}>
      <div className="filters-item-header" onClick={handleCollapseOpen}>
        <h5 style={!isOpen && hasSelectedItem ? { fontWeight: 'bold' } : {}}>
          {t(`filters_${title}_title`)}
        </h5>
        <MdArrowForwardIos className="toggle-body-icon" size={18} />
      </div>
      <div data-scroll-id={title} className="filters-item-body">
        <Collapse isOpen={isOpen}>
          {Array.isArray(data) ? (
            (sortableFilters.includes(title)
              ? sortFilterByLabel(data, t)
              : data
            ).map((el, i) => (
              <FilterItemInput
                t={t}
                {...el}
                title={title}
                key={`${el.label}-${i}`}
                handleFilterChange={handleFilterChange}
              />
            ))
          ) : (
            <>
              {data.tabs && (
                <div className={clsx({ 'd-flex': !!data.options })}>
                  {data.tabs.map(el => (
                    <FilterItemInput
                      t={t}
                      {...el}
                      name={title}
                      type="radio"
                      title={title}
                      key={el.label}
                      groupName="tabs"
                      handleFilterChange={handleFilterChange}
                    />
                  ))}
                </div>
              )}
              {data.options?.reduce((acc, option) => {
                if (selectedGroup && option.tab === selectedGroup.tab) {
                  acc.push(
                    <FilterItemInput
                      t={t}
                      {...option}
                      title={title}
                      groupName="options"
                      key={`${option.label}-${option.tab}`}
                      handleFilterChange={handleFilterChange}
                    />,
                  );
                }

                return acc;
              }, [])}
              {Object.entries(data.section || {}).map(([sectionKey, value]) => (
                <div key={sectionKey}>
                  <p data-scroll-id={sectionKey} className="section-title">
                    {t(`filters_${sectionKey}_title`)}
                  </p>
                  {value.map(item => (
                    <FilterItemInput
                      t={t}
                      {...item}
                      title={title}
                      groupName="section"
                      key={item.label}
                      section={sectionKey}
                      handleFilterChange={handleFilterChange}
                    />
                  ))}
                </div>
              ))}
            </>
          )}
        </Collapse>
      </div>
    </div>
  );
};

export default FilterItem;
