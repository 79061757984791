import { useState, memo, useCallback, useRef, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';

import Slider from 'Components/Slider';

import { getSelectedFilters } from 'utils/filters';

import Api from 'services/api';

import MainFilters from './MainFilters';
import useStyles from './styles';

const Filters = ({ generateListingPayload }) => {
  const router = useRouter();
  const t = useTranslations();

  const selectedFilterRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilters, setSelectedFilter] = useState();
  const { filterButtons, filterButton, selectedFilterButton } = useStyles();

  useEffect(() => {
    if (router.query.selectedFilters) {
      (async () => {
        const {
          data: { result },
        } = await Api.gatAdSelectedFilters(router.query.selectedFilters);

        setSelectedFilter(result);
      })();
    }
  }, [router.query.selectedFilters]);

  const handleCloseFilters = useCallback(() => setIsOpen(false), []);
  const toggleFiltersOverlay = () => setIsOpen(prevIsOpen => !prevIsOpen);

  return (
    <div>
      <div className={filterButtons}>
        <div>
          <button className={filterButton} onClick={toggleFiltersOverlay}>
            {t('filters_title')}
          </button>
        </div>
        <Slider needToRender={getSelectedFilters(selectedFilters).length}>
          {selectedFilters &&
            getSelectedFilters(selectedFilters).map(
              ({ value, count, label }) => (
                <button
                  onClick={() => {
                    selectedFilterRef.current = label;
                    setIsOpen(true);
                  }}
                  className={selectedFilterButton}
                  key={value}
                >
                  {`${t(value)} (${count})`}
                </button>
              ),
            )}
        </Slider>
      </div>

      {isOpen && (
        <MainFilters
          selectedFilterRef={selectedFilterRef}
          handleCloseFilters={handleCloseFilters}
          generateListingPayload={generateListingPayload}
        />
      )}
    </div>
  );
};

export default memo(Filters);
