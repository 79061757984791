import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';

export default makeStyles({
  footer: {
    bottom: 'env(safe-area-inset-bottom, 0)',
    position: 'fixed',
    padding: '16px 17px',
    backgroundColor: variables.$darkOpacity50,
    width: ({ deviceType }) => (deviceType === 'mobile' ? '100%' : '376px'),
  },
});
