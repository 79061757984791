import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  filterItem: {
    '& .filters-item-header': {
      '& svg': {
        cursor: 'pointer',
      },
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& h5': {
        margin: 0,
        padding: '18px 0',
        color: '#FFFFFF',
        fontSize: '13px',
        fontFamily: 'Arial',
        lineHeight: '15px',
        letterSpacing: '0.2px',
      },
    },
    '& .toggle-body-icon': {
      fontWeight: 'bold',
      transform: ({ isOpen }) => (isOpen ? 'rotate(-90deg)' : 'rotate(90deg)'),
      transition: 'transform .2s ease-in-out',
    },
    '& .filter-item-content': {
      padding: '12px 0',
      width: '100%',
    },
    '& .section-title': {
      color: 'white',
      paddingTop: 10,
      fontSize: 13,
    },
  },
});
